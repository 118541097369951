<template>
  <div>
    <vx-card title="🏢📥 Ingreso de Proyecto" title-color="black">
      <p class="mb-1">
        Rellene todos los campos necesarios para la creación de su nuevo
        proyecto.
      </p>
    </vx-card>
    <br />
    <vx-card title="Información general">
      <div class="mt-4">
        <vs-row>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Etapa del Proyecto</span>
                <span class="text-red-500"> *</span>
              </p>
              <v-select
                v-model="stage"
                :options="this.projectStages"
              ></v-select>
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Tipo de proyecto</span>
                <span class="text-red-500"> *</span>
              </p>
              <v-select v-model="type" :options="this.projectTypes"></v-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Nombre</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                size="large"
                icon-pack="feather"
                class="w-full"
                icon="icon-edit"
                color="primary"
                v-validate="'required'"
                name="Nombre_Proyecto"
                v-model="name"
                :placeholder="name"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Cantidad de niveles</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                size="large"
                icon-pack="feather"
                class="w-full"
                icon="icon-layers"
                color="primary"
                type="number"
                v-validate="'required'"
                name="Niveles"
                v-model="levelsCount"
              />
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full mb-3">
              <p>
                <span>Cantidad de apartamentos</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                size="large"
                icon-pack="feather"
                class="w-full"
                icon="icon-grid"
                color="primary"
                type="number"
                v-validate="'required'"
                name="Apartamentos"
                v-model="propertiesCount"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Clasificación del proyecto</span>
                <span class="text-red-500"> *</span>
              </p>
              <v-select
                v-model="classification"
                :options="this.classifications"
              ></v-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full mb-3">
              <p>
                <span>Fecha de Lanzamiento</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                size="large"
                icon-pack="feather"
                class="w-full"
                icon="icon-calendar"
                color="primary"
                type="date"
                v-validate="'required'"
                name="Fecha de Lanzamiento"
                v-model="dateRelease"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Fecha de Finalización</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                size="large"
                icon-pack="feather"
                class="w-full"
                icon="icon-calendar"
                color="primary"
                type="date"
                v-validate="'required'"
                name="Fecha de Finalización"
                v-model="dateEnding"
              />
            </div>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full mb-3">
              <p>
                <span>Sitio web</span>
              </p>
              <vs-input
                size="large"
                icon-pack="feather"
                class="w-full"
                icon="icon-globe"
                color="primary"
                v-validate="'required'"
                required="true"
                name="Landing o Website"
                v-model="website"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full mb-3">
              <p>
                <span>Número de WhatsApp</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                size="large"
                icon-pack="feather"
                class="w-full"
                icon="icon-phone"
                color="primary"
                v-validate="'required'"
                required="true"
                name="whatsAppNumber"
                v-model="whatsAppNumber"
              />
            </div>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full mb-3">
              <p>
                <span>Precio desde</span>
              </p>
              <vs-input
                size="large"
                class="w-full"
                color="primary"
                v-validate="'required'"
                required="true"
                name="Landing o Website"
                v-model.number="priceFrom"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full mb-3">
              <p>
                <span>Precio hasta</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                size="large"
                class="w-full"
                color="primary"
                v-validate="'required'"
                required="true"
                name="whatsAppNumber"
                v-model.number="priceTo"
              />
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-6">
          <vs-col class="flex sm:w-full px-2">
            <div class="w-full sm:w-full mb-3">
              <p>
                <span>Descripción</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-textarea
                counter="250"
                placeholder="Descripción"
                v-model="description"
                :counter-danger.sync="counterDanger"
                height="350"
              />
            </div>
          </vs-col>
          <vs-col class="sm:w-full lg:w-1/2">
            <div class="w-full sm:w-full px-2">
              <Popup
                :title="`Logo de proyecto`"
                :showPopup="logoPopup"
                @close="closePopUp"
              >
                <ImageUpload
                  :resize="{
                    width: 700,
                    height: 700,
                    quality: 60,
                  }"
                  :crop="{
                    ratioX: 1,
                    ratioY: 1,
                  }"
                  :dir="logoDir"
                  @cancel-upload="cancelUpload"
                  @upload="uploadImage"
                  slot="content"
                />
              </Popup>
              <img
                v-if="logoProject"
                :src="logoProject.location"
                class="responsive rounded-lg imagePreview"
              />
              <p>
                <span>Selecciona el logo para tu proyecto</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-button
                @click="logoPopup = true"
                class="w-full mt-6"
                color="success"
                icon-pack="feather"
                icon="icon-upload-cloud"
                >Subir logo</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
    <br />
    <vx-card title="Razón Social">
      <div class="mt-4">
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Nombre</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                icon-pack="feather"
                class="w-full"
                icon="icon-edit"
                color="primary"
                v-validate="'required'"
                name="businessName"
                v-model="businessName"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Dirección</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                icon-pack="feather"
                class="w-full"
                icon="icon-map-pin"
                color="primary"
                v-validate="'required'"
                name="businessAddress"
                v-model="businessAddress"
              />
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
    <br />
    <vx-card title="Ubicacion y moneda">
      <div class="mt-4">
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>País</span>
                <span class="text-red-500"> *</span>
              </p>
              <v-select
                placeholder="País"
                class="mt-1 mb-3"
                v-model="country"
                @input="changeCities"
                :options="this.countries"
              ></v-select>
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Ciudad</span>
                <span class="text-red-500"> *</span>
              </p>
              <v-select
                placeholder="Ciudad"
                autocomplete="off"
                class="mt-1 mb-3"
                :disabled="!cities.length"
                v-model="city"
                @input="changeZones"
                :options="cities"
              ></v-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Zona</span>
              </p>
              <v-select
                placeholder="Zona"
                class="mt-1 mb-3"
                :disabled="!zones.length"
                v-model="zone"
                @input="changeNeighborhood"
                :options="this.zones"
              ></v-select>
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Vecindario</span>
              </p>
              <v-select
                placeholder="Vecindario"
                autocomplete="off"
                class="mt-1 mb-3"
                :disabled="!neighborhoods.length"
                v-model="neighborhood"
                :options="this.neighborhoods"
              ></v-select>
            </div>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Dirección completa</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                icon-pack="feather"
                class="w-full"
                icon="icon-map-pin"
                color="primary"
                v-validate="'required'"
                name="Direccion_Proyecto"
                v-model="address"
                placeholder="Direccion"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Moneda</span>
                <span class="text-red-500"> *</span>
              </p>
              <v-select
                placeholder="Seleccione moneda"
                class="mt-1 mb-3"
                v-model="countryCurrency"
                :disabled="!country"
                :options="countryCurrencies"
              ></v-select>
            </div>
          </vs-col>
        </vs-row>
        <br />
        <span class="mt-4">
          Mueve el marcador para actualizar ubicación de {{ name }}
        </span>
        <gmap-map
          v-if="mapPosition"
          :center="mapPosition"
          :zoom="12"
          style="width: 100%; height: 300px"
          class="mt-4 mb-2"
        >
          <gmap-info-window :options="infoOptions"></gmap-info-window>
          <gmap-marker
            :position="mapPosition"
            :draggable="true"
            :clickable="true"
            @drag="updateCoordinates"
          ></gmap-marker>
        </gmap-map>
      </div>
    </vx-card>
    <br />
    <vx-card
      :title="`Preferencias de ${$tc(
        'message.Downpayment',
        2,
        this.countryCode
      )}`"
    >
      <div class="mt-4">
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Nombre</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                v-model.trim="dowmpaymentTypeName"
                size="large"
                class="w-full"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>
                  Porcentaje (%) de
                  {{ $tc("message.downpayment", 2, this.countryCode) }}
                </span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                v-model.number="downpaymentRate"
                type="number"
                size="large"
                class="w-full"
              />
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>
                  Monto de {{ $tc("message.reservation", 2, this.countryCode) }}
                </span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                v-model.number="reservation"
                type="number"
                class="w-full"
                size="large"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span> Meses a pagar </span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                v-model.number="maxDownpaymentInstallments"
                type="number"
                class="w-full"
                size="large"
              />
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span> Porcentaje (%) a pagar en PCV </span>
              </p>
              <vs-input
                v-model.number="pcvRate"
                type="number"
                class="w-full"
                size="large"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span> Días para pago de PCV </span>
              </p>
              <vs-input
                v-model.number="pcvDays"
                type="number"
                class="w-full"
                size="large"
              />
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
    <br />
    <vx-card title="Bancos y tasa de interés">
      <div class="mt-4">
        <vs-row>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Banco</span>
                <span class="text-red-500"> *</span>
              </p>
              <v-select v-model="bankSelected" :options="this.banks"></v-select>
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Porcantaje (%) de tasa de interes</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                class="w-full"
                color="primary"
                type="number"
                v-validate="'required'"
                name="bank_interest_rate"
                v-model.number="bankInterestRate"
              />
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Años de financiamiento</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                class="w-full"
                color="primary"
                v-validate="'required'"
                name="bank_max_years"
                v-model.number="bankMaxYears"
              />
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
    <br />
    <vx-card title="Preferencias de proyecto">
      <div class="mt-4">
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span>Díaz de validez de cotización</span>
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                class="w-full"
                color="primary"
                v-validate="'required'"
                name="bank_max_years"
                v-model.number="maxDaysQuote"
              />
            </div>
          </vs-col>
          <vs-col class="flex sm:w-full lg:w-1/2 px-2">
            <div class="w-full sm:w-full">
              <p>
                <span
                  >Díaz de validez de
                  {{ $tc("message.optioned", 2, this.countryCode) }}</span
                >
                <span class="text-red-500"> *</span>
              </p>
              <vs-input
                class="w-full"
                color="primary"
                v-validate="'required'"
                name="bank_max_years"
                v-model.number="maxDaysOptioned"
              />
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
    <br />
    <vx-card :title="`Impuestos ${country.label}`" v-if="showTaxes">
      <div class="mt-4">
        <vs-row class="mt-4">
          <vs-col class="flex sm:w-full px-2">
            <div class="w-full sm:w-full">
              <vs-alert
                :active.sync="showTaxesAlert"
                color="danger"
                icon="information"
              >
                <span
                  >Impuestos para {{ country.label }} son requeridos. Has clic
                  <b @click="reloadTaxes">aqui</b> para verlos de nuevo.</span
                >
              </vs-alert>
              <vs-table :data="taxes" v-if="taxes.length">
                <template slot="thead">
                  <vs-th> Nombre </vs-th>
                  <vs-th> Porcentaje </vs-th>
                  <vs-th> Proporcion (%) </vs-th>
                  <vs-th> Acciones </vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].name">
                      {{ data[indextr].name }}
                    </vs-td>
                    <vs-td :data="data[indextr].rate">
                      {{ data[indextr].rate }}%
                    </vs-td>
                    <vs-td :data="data[indextr].proportion">
                      <vs-input
                        size="small"
                        icon-pack="feather"
                        class="w-full"
                        color="primary"
                        type="number"
                        v-validate="'required'"
                        required="true"
                        min="1"
                        max="100"
                        :name="`tax${indextr}`"
                        v-model="data[indextr].proportion"
                      />
                    </vs-td>
                    <vs-td>
                      <vs-button
                        class="ml-3"
                        type="flat"
                        icon-pack="feather"
                        color="danger"
                        @click="removeTax(data[indextr].id)"
                        icon="icon-trash"
                      ></vs-button>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vx-card>
    <br /><br />
    <vs-row>
      <vs-col>
        <vs-button
          color="success"
          :disabled="!fieldsReady"
          ref="loadableButton"
          id="button-with-loading"
          class="vs-con-loading__container"
          @click="createProject"
          icon-pack="feather"
          icon="icon-save"
          type="filled"
          >Crear Proyecto : {{ name }}</vs-button
        >
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import router from "../../../router";
import vSelect from "vue-select";
import { api, taxApi, financingApi } from "../services";
import { country, bank as bankApi } from "../../../services";
import { sentryCaptureException } from "../../../helpers/Sentry";
import Backbutton from "../../../layouts/Backbutton.vue";
import slugify from "../../../helpers/slugify";
import Popup from "../../../components/Popup.vue";
import ImageUpload from "../../../components/ImageUpload.vue";
import { getGeolocation } from "../../../utils/geolocation";

export default {
  data() {
    return {
      projectStages: [],
      projectTypes: [],
      classifications: ["VIS", "MUVI", "NO VIS"],
      countries: [],
      cities: [],
      zones: [],
      neighborhoods: [],
      stage: "",
      type: "",
      classification: "",
      name: "",
      description: "",
      website: "",
      propertiesCount: 0,
      levelsCount: 0,
      address: "",
      zone: "",
      neighborhood: "",
      city: "",
      country: "",
      countryCode: "co",
      counterDanger: false,
      mapPosition: { lat: 6.2435163, lng: -75.6931375 },
      location: { lat: 6.2435163, lng: -75.6931375 },
      infoOptions: {
        pixelOffset: { width: 0, height: -35 },
      },
      backgroundLoading: "success",
      colorLoading: "#ffffff",
      project: null,
      whatsAppNumber: null,
      showTaxes: false,
      showTaxesAlert: false,
      taxes: [],
      paymentDaysReservation: [1],
      dowmpaymentTypeName: "Default",
      downpaymentRate: 0,
      reservation: 0,
      pcvRate: 0,
      pcvDays: 0,
      maxDownpaymentInstallments: 0,
      logoPopup: false,
      logoProject: null,
      countryCurrency: null,
      countryCurrencies: [],
      banks: [],
      bankSelected: "",
      bankMaxYears: 0,
      bankInterestRate: 0,
      maxDaysQuote: 30,
      maxDaysOptioned: 2,
      dateRelease: "",
      dateEnding: "",
      businessName: "",
      businessAddress: "",
      priceFrom: 0,
      priceTo: 0,
    };
  },
  components: {
    "v-select": vSelect,
    Backbutton,
    Popup,
    ImageUpload,
  },
  async beforeMount() {
    try {
      const geolocation = await getGeolocation();

      this.mapPosition = {
        lat: geolocation.latitude,
        lng: geolocation.longitude,
      };
      this.location = this.mapPosition;
    } catch (e) {
      sentryCaptureException(e, "project");
    }
  },
  async mounted() {
    try {
      const projectStages = await api.getAvailableProjectStages();
      this.projectStages = projectStages.map((obj) => {
        return { label: obj.name, value: obj.id };
      });

      const projectTypes = await api.getTypes();
      this.projectTypes = projectTypes.map((obj) => {
        return { label: obj.name, value: obj.id };
      });

      const countries = await country.getCountries();
      this.countries = countries.map((obj) => {
        return {
          label: obj.name,
          value: obj.id,
          cities: obj.cities,
          code: obj.code,
        };
      });
    } catch (e) {
      sentryCaptureException(e, "project");

      this.$vs.notify({
        title: `Error`,
        text: `Ha ocurrido un error al intentar iniciar módulo de proyectos.`,
        color: "danger",
      });
    }
  },
  computed: {
    logoDir() {
      const projectName = slugify(this.name);
      const developerName = slugify(
        this.$store.getters["auth/getUser"].developerName
      );

      return `${developerName}/projects/${projectName}/gallery`;
    },
    fieldsReady() {
      if (
        !this.stage ||
        !this.name ||
        !this.description ||
        !this.address ||
        !this.country ||
        !this.city ||
        !this.levelsCount ||
        !this.propertiesCount ||
        !this.classification ||
        !this.dowmpaymentTypeName ||
        !this.downpaymentRate ||
        !this.reservation ||
        !this.maxDownpaymentInstallments ||
        !this.logoProject ||
        !this.countryCurrency ||
        !this.bankSelected ||
        !this.bankMaxYears ||
        !this.bankInterestRate ||
        !this.maxDaysQuote ||
        !this.maxDaysOptioned ||
        !this.dateRelease ||
        !this.dateEnding
      ) {
        return false;
      } else {
        return true;
      }
    },
    gisLocation() {
      const location = {
        type: "Point",
        crs: {
          type: "name",
          properties: { name: "urn:ogc:def:crs:EPSG::4326" },
        },
      };

      location.coordinates = [this.location.lat, this.location.lng];

      return location;
    },
  },
  watch: {
    country(val) {
      this.showTaxesAlert = false;
      this.showTaxes = false;
      this.countryCode = val.code;
      this.countryCurrencies = [];
      this.countryCurrency = null;

      const countryId = val.value;
      this.getTaxes(countryId);
      this.getCurrencies(countryId);
      this.getBanks(countryId);
    },
    taxes(val) {
      if (!val.length && this.showTaxes) {
        this.showTaxesAlert = true;
      }
    },
    bankSelected(val) {
      this.bankInterestRate = val.defaultInterestRate;
      this.bankMaxYears = val.maxYears;
    },
  },
  methods: {
    async getTaxes(countryId) {
      this.taxes = await taxApi.countryTaxes(countryId);
      if (this.taxes.length) this.showTaxes = true;
    },
    removeTax(taxId) {
      const taxesSelected = this.taxes.filter((i) => i.id != taxId);
      this.taxes = taxesSelected;
    },
    reloadTaxes() {
      this.getTaxes(this.country.value);
      this.showTaxesAlert = false;
    },
    changeCities(countryId) {
      const country = this.countries.filter(
        (obj) => obj.value == countryId.value
      );
      this.cities = country[0].cities.map((obj) => {
        return { label: obj.name, value: obj.id };
      });
      this.city = null;
      this.zones = [];
      this.neighborhoods = [];
      this.zone = null;
      this.neighborhood = null;
    },
    async changeZones(cityId) {
      const zones = await country.getZones(cityId.value);
      this.zones = zones.map((obj) => {
        return { label: obj.name, value: obj.id, neighborhoods: obj.suburbs };
      });
      this.zone = null;
      this.neighborhoods = [];
      this.neighborhood = null;
    },
    changeNeighborhood(zoneId) {
      const zone = this.zones.filter((obj) => obj.value == zoneId.value);
      this.neighborhoods = zone[0].neighborhoods.map((obj) => {
        return { label: obj.name, value: obj.id };
      });
      this.neighborhood = null;
    },
    async createProject() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45,
      });

      try {
        this.project = await api.addProject({
          name: this.name,
          description: this.description,
          website: this.website,
          propertiesCount: parseInt(this.propertiesCount),
          levelsCount: parseInt(this.levelsCount),
          stage: this.stage.value,
          type: this.type.value,
          classification: this.classification,
          address: this.address,
          city: this.city.value,
          zone: this.zone ? this.zone.value : null,
          neighborhood: this.neighborhood ? this.neighborhood.value : null,
          location: this.gisLocation,
          developerId: this.$store.getters["auth/getUser"].developerId,
          paymentDaysReservation: this.paymentDaysReservation,
          countryCurrencyId: this.countryCurrency.value || null,
          maxDaysOptioned: this.maxDaysOptioned,
          maxDaysQuote: this.maxDaysQuote,
          dateRelease: this.dateRelease,
          dateEnding: this.dateEnding,
          businessName: this.businessName,
          businessAddress: this.businessAddress,
        });

        if (this.whatsAppNumber) {
          await api.addProjectSetting(
            this.project.id,
            this.whatsAppNumber,
            "WHATSAPP_SHOW_ROOM"
          );
        }

        if (this.logoProject) {
          await api.addProjectImage({
            img_url: this.logoProject.location,
            project_image_gallery: "COVER",
            project_id: this.project.id,
          });
        }

        if (this.priceFrom) {
          await api.addProjectSetting(
            this.project.id,
            String(this.priceFrom),
            "PRICE_FROM"
          );
        }

        if (this.priceTo) {
          await api.addProjectSetting(
            this.project.id,
            String(this.priceTo),
            "PRICE_TO"
          );
        }

        if (this.taxes.length) {
          const projectTaxes = this.taxes.map((tax) => {
            return {
              tax_id: tax.id,
              project_id: this.project.id,
              proportion: tax.proportion,
            };
          });
          await api.addProjectTaxes(projectTaxes);
        }

        const downpayment = {
          project_id: this.project.id,
          name: this.dowmpaymentTypeName,
          downpayment_rate: this.downpaymentRate,
          reservation: this.reservation,
          pcv_rate: !this.pcvRate ? 0 : this.pcvRate,
          pcv_payment_day: !this.pcvDays ? 0 : this.pcvDays,
          max_payment_installments: this.maxDownpaymentInstallments,
        };

        await api.addDownpaymentType(downpayment);

        await financingApi.add(
          this.bankSelected.label,
          this.bankInterestRate,
          this.bankMaxYears,
          this.project.id,
          this.bankSelected.value
        );

        router.push({ path: "/proyectos" });

        this.$vs.notify({
          time: 6000,
          title: `${this.name} creado! 🏢🚀`,
          text: `Fue creado correctamente.`,
          color: "success",
        });
      } catch (e) {
        sentryCaptureException(e, "project");

        this.$vs.notify({
          title: `No ha sido posible crear el proyecto`,
          text: `No ha sido posible crear el proyecto`,
          color: "danger",
        });
      }

      this.$vs.loading.close("#button-with-loading > .con-vs-loading");
    },
    updateCoordinates(location) {
      this.location = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    closePopUp() {
      this.logoPopup = false;
    },
    cancelUpload() {
      this.logoPopup = false;
    },
    uploadImage(images) {
      this.logoProject = images.length ? images[0] : null;
      this.logoPopup = false;
    },
    async getCurrencies(countryId) {
      const currencies = await country.getCurrencies(countryId);

      this.countryCurrencies = currencies.map((i) => {
        return {
          label: `${i.currency.code} ${i.currency.name}`,
          value: i.id,
        };
      });
    },
    async getBanks(countryId) {
      const getBanks = await bankApi.listByCountry(countryId);

      this.banks = getBanks.map((i) => {
        return {
          label: i.name,
          value: i.id,
          defaultInterestRate: i.default_interest_rate,
          maxYears: i.max_years,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.imagePreview {
  width: 60%;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
}
</style>
