<template>
  <div>
    <button
      @click="backHistoryUrl()"
      class="flex mt-6 font-light text-2xl hover:text-blue-flattlo ml-1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 mr-2 mt-1"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 19l-7-7m0 0l7-7m-7 7h18"
        /></svg
      >Regresar
    </button>
  </div>
</template>

<script>
import router from "../router";
export default {
  data() {
    return{}
  },
  methods: {
    backHistoryUrl(){
      router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped></style>
